import * as React from 'react'

import NavbarLayout from '../components/navbarLayout'
import FooterLayout from '../components/footerLayout'

const Contact = () => {
    return (
        <body> 
            <NavbarLayout> </NavbarLayout>
            <FooterLayout></FooterLayout>
        </body>
    )
}

export default Contact
